
const prevBtnSelector = '.js-prev-page';
const nextBtnSelector = '.js-next-page';

/** Check if btn exists and init listeners */
const initKeyboardControls =()=> {
    initKeyboardControl(prevBtnSelector,keyboardListenerArrowLeft);
    initKeyboardControl(nextBtnSelector,keyboardListenerArrowRight);
};

/** Remove listeners */
const destroyKeyboardListeners =()=> {
    document.removeEventListener('keyup',keyboardListenerArrowRight);
    document.removeEventListener('keyup',keyboardListenerArrowLeft);
};

/**
 * @param {string} btnSelector
 * @param {function} listenerFn
 */
const initKeyboardControl =(btnSelector,listenerFn)=> {
    const btn = document.querySelector(btnSelector);
    if (!btn) return;
    document.addEventListener('keyup',listenerFn);
};

/**
 * @param {object<code>} code
 * @returns {boolean|void}
 */
const keyboardListenerArrowRight=({code,key})=>
    (code === "ArrowRight" || key === "Right") && triggerClick(nextBtnSelector);

/**
 * @param {object<code>} code
 * @returns {boolean|void}
 */
const keyboardListenerArrowLeft=({code,key})=>
    (code === "ArrowLeft" || key === "Left") && triggerClick(prevBtnSelector);

/**
 * Btn is not being passed as an argument but
 * is selected again in due of possibility `destroy` listeners
 * @param {string }btnSelector
 */
const triggerClick=(btnSelector)=> {
    const btn = document.querySelector(btnSelector);
    if (btn) btn.click();
};

export {
    initKeyboardControls,
    destroyKeyboardListeners
};