/** Nette */
import 'nette.ajax.js';
import './../../../vendor/vojtech-dobes/nette-ajax-history/client-side/history.ajax';
import {destroyKeyboardListeners, initKeyboardControls} from "./keyboardControls";

$(function(){

    // ---- Nette ---------------------------------------------------------------------------------------------------

    $.nette.init();
    $.nette.ext('ajax-handler', {
        start: function (event, status) {
            const $el = $(status.nette.ui);
            const $loading = $('#loading');
            if ($el.attr('data-switch-content') != 'undefined' && $loading.length) {
                const offset = $('.header').height() + 15;
                $loading.show();
                $('html, body').animate({ scrollTop: $loading.offset().top - offset }, 250);
            }
            destroyKeyboardListeners();
            destroyScrolls();
        },
        complete: function (payload) {
            $('#loading').fadeOut();
            initToggle();
            initKeyboardControls();
            initScrolls();
        }
    });

    initKeyboardControls();

    // ---- ScrollTop --------------------------------------------------------------------------------------------------

    const initScrolls=()=>{
        const els = document.querySelectorAll('[data-scroll]');
        if (!els.length) return;
        for (let i = 0; i < els.length; i++)
            els[i].addEventListener('click',scrollTop)
    };

    const destroyScrolls=()=>{
        const els = document.querySelectorAll('[data-scroll]');
        if (!els.length) return;
        for (let i = 0; i < els.length; i++)
            els[i].removeEventListener('click',scrollTop)
    };

    const scrollTop=(e)=>{
        try {
            window.scrollTo({
                ...{top: 0, behavior: 'smooth'},
                ...JSON.parse(e.currentTarget.dataset.scroll.toString())
            });
        } catch (err) {
            console.log(err);
        }
    };

    initScrolls();

    // ---- Toggle --------------------------------------------------------------------------------------------------

    const initToggle =()=> {
        const els = document.querySelectorAll('[data-toggle]');
        if (!els.length) return;
        for (let i = 0; i < els.length; i++)
            els[i].addEventListener('click',toggle)
    };

    const toggle =(e)=> {
        if (!e || !e.target) return;
        const querySelector = e.target.getAttribute('data-toggle');
        if (!querySelector) return;
        const targets = document.querySelectorAll(querySelector);
        for (let i = 0; i < targets.length; i++) {
            toggleClass(targets[i],'toggled');
            toggleClass(e.target,'toggle-toggled');
        }
    };

    const toggleClass =(element,classname)=> {
        element.classList.contains(classname)
            ? element.classList.remove(classname)
            : element.classList.add(classname);
    };

    initToggle();


    // ---- Cookie bar  ---------------------------------------------------------------------------------------------

    /*const showCookieBar=()=>
        document.getElementById('cookiebar').hidden = false;

    const showCookiesEls = document.querySelectorAll('.js-show-cookie');
    for (let i = 0; i < showCookiesEls.length; i++)
        showCookiesEls[i].addEventListener('click',showCookieBar);*/

});
